import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import ErrorFallBackUI from './ErrorFallBackUI';
import { ErrorBoundaryService } from '../../../services/errorBoundaryService';

export class ErrorBoundary extends Component {
    state = { hasError: false, error: "", errorInfo: {} };

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, error: error, errorInfo: {} };
    }

    componentDidCatch(error, errorInfo) {
        // log the error to an error reporting service or console
        const URL = window.location.href
        const params = window.location.search
        new ErrorBoundaryService().sendErrorReport({error_msg: error.message, error_stack: error.stack}, errorInfo, URL, params)
        this.setState({ hasError: true, error: error, errorInfo: errorInfo })
    }

    render() {
        return this.state.hasError ? <ErrorFallBackUI {...{ ...this.state }} /> : this.props.children;
    }
}

export default withRouter(ErrorBoundary)