export const CAMPAIGN_TYPE_TO_LABEL = {
    "sp": "Sponsored Products",
    "sb": "Sponsored Brands",
    "sd": "Sponsored Display",
    "dsp": "Demand Side Platform (DSP)"
}

export const ATTRIBUTION_MODEL_TO_LABEL = {
    "first_touch_attribution": "First Touch Attribution",
    "last_touch_attribution": "Last Touch Attribution",
    "linear_touch_attribution": "Linear Touch Attribution",
    "position_based_attribution": "Position Based Attribution",
}

export const REFRESH_PERIOD_FIXED_OPTIONS = [
    { label: 'Every 7 Days', value: 7 },
    { label: 'Every 30 Days', value: 30 },
    { label: 'Every 90 Days', value: 90 },
    { label: 'Every 180 Days', value: 180 },
    { label: 'Every 365 Days', value: 365 },
];

export const REFRESH_PERIOD_VARIABLE_OPTIONS = [
    { label: "Start of Day", value: "START_OF_DAY" },
    { label: "Start of Week", value: "START_OF_WEEK" },
    { label: "Start of Month", value: "START_OF_MONTH" },
];

export const AGGREGATION_PERIOD_FIXED_OPTIONS = [
    { label: 'Last 7 Days', value: 7 },
    { label: 'Last 30 Days', value: 30 },
    { label: 'Last 90 Days', value: 90 },
    { label: 'Last 180 Days', value: 180 },
    { label: 'Last 365 Days', value: 365 },
];

export const AGGREGATION_PERIOD_VARIABLE_OPTIONS = [
    { label: 'Last Month', value: "LAST_MONTH" },
    { label: 'Current Month To Date', value: "CURRENT_MONTH" },
];

export const TIME_TO_CONVERSION_CATEGORY_TO_LABEL = {
    "< 1 DAY": "< 1 Day",
    "1 - 7 DAYS": "1 - 7 Days",
    "7 - 14 DAYS": "7 - 14 Days",
    "14 - 30 DAYS": "14 - 30 Days",
    "30+ DAYS": "30+ Days",
};

export const METRICS = Object.freeze({
    SPEND: 'spend',
    REACH: 'reach',
    IMPRESSIONS: 'impressions',
    CLICKS: 'clicks',
    CTR: 'ctr',
    CUSTOMERS: 'customers',
    ORDERS: 'orders',
    SALES: 'sales',
    ACOS: 'acos',
    ROAS: 'roas',
    CPM: 'cpm',
    PERCENTAGE: 'percentage',
    CONVERSION_RATE: 'conversion_rate',
    CONVERSION_RATE_PER_CLICK: 'conversion_rate_per_click',
    CONVERSION_RATE_PER_THOUSAND_IMPRESSIONS: 'conversion_rate_per_thousand_impressions',
});

export const GRANULARITY = Object.freeze({
    CAMPAIGN: 'campaign',
    CAMPAIGN_TYPE: 'campaign_type',
})

export const REPORT_STATUS = Object.freeze({
    NOT_READY: "NOT_READY",
    FAILED: "FAILED",
    READY: "READY",
    STALE: "STALE",
    UPDATE_FAILED: "UPDATE_FAILED",
})

export const REPORT_STATUS_TO_LABEL = {
    [REPORT_STATUS.NOT_READY]: "Report is not ready yet",
    [REPORT_STATUS.FAILED]: "Failed to generate report",
    [REPORT_STATUS.READY]: "Ready",
    [REPORT_STATUS.STALE]: "Stale",
    [REPORT_STATUS.UPDATE_FAILED]: "Update Failed",
}

export const REPORT_STATUS_WITH_OUTDATED_DATA = [REPORT_STATUS.STALE, REPORT_STATUS.UPDATE_FAILED];
export const REPORT_STATUS_WITH_DATA = [...REPORT_STATUS_WITH_OUTDATED_DATA, REPORT_STATUS.READY]
export const REPORT_STATUS_WITHOUT_DATA = [REPORT_STATUS.NOT_READY, REPORT_STATUS.FAILED];
