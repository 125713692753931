import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import 'font-awesome/scss/font-awesome.scss';
import Loader from './layout/Loader';
import Aux from "./hoc/_Aux";
import ScrollToTop from './layout/ScrollToTop';
import routes from "./route";
import Loadable from 'react-loadable';
import { useAuth } from "./context/AuthContext";
import GuestLayout from './layout/GuestLayout';
import AuthedContextWrapped from './layout/AuthedContextWrapped';
import { isSharedEnv } from './service';
import { getEncodedRedirectParam } from './utils/locationUtils';

const AdminLayout = Loadable({
    loader: () => import('./layout/AdminLayout'),
    loading: Loader
});

function App() {
    const getRedirectUrl = () => {
        const search = window.location.search
        if (search.length > 0) {
            let queries = new URLSearchParams(search);
            return atob(queries.get('redirect') || btoa("/"))
        }
        return "/"
    }

    const auth = useAuth();
    const menu = routes[isSharedEnv ? "shared" : "app"].map((route, index) => {
        return (route.component) ? (
            <Route
                key={index}
                path={route.path}
                exact={route.exact}
                name={route.name}
                render={props => (
                    auth.authenticated ? <Redirect to={{ pathname: getRedirectUrl(), state: { from: props.location } }} /> :
                        <route.component {...props} defaultProps={route.defaultProps} />
                )} />
        ) : (null);
    });

    const signinUrlPath = (isSharedEnv ? "/guest/auth/signin" : "/auth/signin") + `?${getEncodedRedirectParam()}`

    return (
        <Aux>
            <ScrollToTop>
                <Suspense fallback={<Loader />}>
                    <Switch>
                        {menu}
                        <Route path="/"
                            render={
                                props => auth.authenticated ?
                                    isSharedEnv ?
                                        <GuestLayout /> :
                                        <AuthedContextWrapped>
                                            <AdminLayout />
                                        </AuthedContextWrapped>
                                    : <Redirect to={signinUrlPath} />
                            } />
                    </Switch>
                </Suspense>
            </ScrollToTop>
        </Aux>
    );
}

export default App;
