import { intlFormatDistance } from "date-fns";
import React, { useState, useContext } from "react";
import { Dropdown } from "react-bootstrap";
import { handleSubmitCampaignSettingChange } from "../../features/campaigns/campaign_utils";
import { capitalizeString } from "../../features/common/utils/manipulations";
import { updateCampaign } from "../../service";
import { BIDDING_STRATEGIES, METRIC_TYPES, COUNTRY_TO_NUMBER_FORMAT, TARGET_INFO_KEYWORDS } from "../../store/constant";
import { getDeepValueFromObject, isAsin, prefixCurrency, round } from "../../Util";
import RedGreenText from "./redGreenText";
import TableCellText from "./TableCellText";
import TextEdit from "./text_edit";
import { INVENTORY_FIELDS, InventoryData } from "../../features/products/Classes/InventoryData";
import { updateAdGroups } from "../../service";
import { accountContext } from "../../context/AccountContext";
import { CreateMetricCellForExpanded } from "../../utils/graphUtils";
import { CAMPAIGN_TYPE_TO_LABEL as AMC_CAMPAIGN_TYPE_TO_LABEL, METRICS as AMC_METRICS, GRANULARITY as AMC_GRANULARITY } from "../../features/amc_reports/constants";
import { DSPMetricConfigs } from "../../features/common/utils/rawFilters";

/**
 * PROP DEFINITION
 * @campaign - <Object>
 * Description: Campaign Data
 * Object Structure: { campaignId: <String>, name: <String>, campaign_type: <String> }
 * 
 * @campaigns - <Array of <Object>>
 * Description: List of Campaign Data
 * Object Structure: { campaignId: <String>, name: <String>, campaign_type: <String> }
 * 
 * @onSuccess - <Function>
 * Description: Function trigger on success of campaign update
 * Props passed: <Array> of updated campaigns
 * 
 * @onError - <Function>
 * Description: Function trigger on failure of campaign update
 * Props passed: JS Error <Object>
 * 
 * @setLoading - <Function>
 * Description: Hook to set loading state
 * Props passed: <Boolean>
 * 
 * @canEdit - <Boolean>
 * Description: Indicates if name is editable or not
 */
function CampaignName({ campaign, campaigns, onSuccess, onError, setLoading, singleTooltipID, canEdit=false }) {

    const { campaignId, name, campaign_type } = campaign
    const [editIconShow, setEditIconShow] = useState(false)


    const handleSubmit = async (name, campaign_type=null, cId=null, campaigns=null) => {
        setLoading && setLoading(true)
        try {
            await updateCampaign({ campaign_type: campaign_type, campaign_id: cId, name: name })
            const idx = campaigns.findIndex(({ campaignId }) => cId === campaignId)

            const updatedCampaigns = [...campaigns];

            if (name) {
                updatedCampaigns[idx] = {...updatedCampaigns[idx], name};
            }

            onSuccess && onSuccess(updatedCampaigns)
        } catch(e) {
            console.error(e)
            onError && onError(e)
        }
    }



    return (
        <div onMouseEnter={()=>setEditIconShow(true)} onMouseLeave={()=>setEditIconShow(false)}>
            {
                canEdit ? 
                    <TextEdit wrap={true} popover={true} singleTooltipID={singleTooltipID} key={name} value={name} onChangeSubmit={(name) => handleSubmit(name, campaign_type, campaignId, campaigns)} textInputWidth="100%" editIcon={editIconShow} link={`/campaigns/${campaign_type}/${campaignId}`} /> : 
                    <a className='inline-anchor' style={{ cursor: "pointer" }}  href={`/campaigns/${campaign_type}/${campaignId}`}>{name}</a>
            }
            
       </div>
    )
}


function AdGroupName({ adGroup, adGroups, onSuccess, onError, setLoading, canEdit = false }) {
    const { adGroupId, name } = adGroup
    const [editIconShow, setEditIconShow] = useState(false)

    const handleSubmit = async (name, adId = null, adGroups = null) => {
        setLoading && setLoading(true)
        try {
            await updateAdGroups({
                selected_adgroups: [{ ...adGroup, name }]
            })
            const idx = adGroups.findIndex(({ adGroupId }) => adId === adGroupId)
            if (name) {
                adGroups[idx].name = name;
            }
            onSuccess && onSuccess(adGroups)
        } catch (e) {
            console.error(e)
            onError && onError(e)
        }
    }


    return (
        <div onMouseEnter={() => setEditIconShow(true)} onMouseLeave={() => setEditIconShow(false)}>
            {
                canEdit ?
                    <TextEdit wrap={true} key={name} value={name} onChangeSubmit={(name) => handleSubmit(name, adGroupId, adGroups)} textInputWidth="100%" editIcon={editIconShow} /> :
                    <TableCellText>{name}</TableCellText>
            }

        </div>
    )
}



const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a className="inline-edit-anchor" ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </a>
));

function CampaignBudget({campaign, campaignList, onSuccess, onError, setLoading, budgetType="budget"}) {

    const handleSubmit = async (newBudget) => {
        setLoading && setLoading(true)
        let newDailyBudget = budgetType === "budget" ? parseFloat(newBudget) : undefined
        let newBaseBudget = budgetType === "base_budget" ? parseFloat(newBudget) : undefined 
        if ((newDailyBudget !== undefined && newDailyBudget <= 0) || (newBaseBudget !== undefined && newBaseBudget <= 0)) {
            onError && onError("Budget must be greater than 0");
            setLoading && setLoading(false);
            return;
        }
        await handleSubmitCampaignSettingChange(campaign.campaign_type, campaign.campaignId, newDailyBudget, undefined, campaignList, onSuccess, onError, newBaseBudget)
    }

    const selector = {
        "budget": "daily_budget",
        "base_budget": "base_budget"
    }

    return <TextEdit key={`${budgetType}Edit` + campaign.campaignId} value={campaign[selector[budgetType]]} formatValue={(val) => prefixCurrency(val)} onChangeSubmit={handleSubmit} />
}

function BiddingStrategy({aggregate, campaign={}, campaignList=[], onSuccess, onError, setLoading}) {
    const { campaign_type, campaignId, bidding = {} } = campaign
    let strategy = bidding ? bidding.strategy : ''
    if (campaign_type === 'sb' || campaign_type == 'sd') {
        return "--"
    }
    let displayableStrategy = BIDDING_STRATEGIES[strategy] || 'Fixed'

    const handleSubmit = (strat) => {
        setLoading && setLoading(true)
        handleSubmitCampaignSettingChange(campaign_type, campaignId, undefined, strat, campaignList, onSuccess, onError, undefined)
    }

    return aggregate ? "--" : (
        <Dropdown className="btn-drpdwn" align="end">
            <Dropdown.Toggle as={CustomToggle} id={`${campaignId}#strategyToggle`}>{displayableStrategy}</Dropdown.Toggle>
            <Dropdown.Menu align="end">
                {
                    Object.keys(BIDDING_STRATEGIES).map((s, i) => (
                        <Dropdown.Item key={i + 1} eventKey={i + 1} onClick={() => handleSubmit(s)}>{BIDDING_STRATEGIES[s]}</Dropdown.Item>
                    ))
                }
            </Dropdown.Menu>
        </Dropdown>
    )
}

function TargetType({ target_type, target }) {
    if (target_type == 'keywords') {
        return `Keyword - ${target.match_type}`
    } else if (target_type == 'targets') {
        if (target.expression_type == 'auto') {
            return `Auto`
        } else {
            return `Product Target`
        }
    }
}

function CampaignThresholdCell(threshold_name, thresholds, campaignId, campaign_type, onSubmit, uniqueId, editable=true) {
    let value = thresholds[threshold_name], formatValueFn = (v) => prefixCurrency(v);
    if (threshold_name.includes('target_acos')) {
        value = round(value * 100)
        formatValueFn = (val) => `${val}%`
    }
    if (threshold_name == 'adequate_impressions') {
        formatValueFn = (v) => v
    }
    
    const updateCampaignThreshold = async (v) => {
        let value = v
        if (threshold_name.includes('target_acos'))
            value = parseFloat(v / 100)
        else if(threshold_name.includes('bid_threshold') || threshold_name.includes('budget_threshold') || threshold_name.includes('adequate_') || threshold_name.includes('wasted'))
            value = parseFloat(v)
        await onSubmit({ campaign_id: campaignId, campaign_type, thresholds: { [threshold_name]: value } })
    }
    if (editable) {
        return <TextEdit key={threshold_name + uniqueId} value={value} formatValue={formatValueFn} onChangeSubmit={updateCampaignThreshold} />
    } else {
        return <TableCellText>{formatValueFn(value)}</TableCellText>
    }
}

function Num({ val, prefixCurr = false, suffix = '', multiplier, missing, precision = 2, countryCode, currencyCode = undefined }) {
    
    let v;
    if ((val === undefined || val === null || isNaN(val)) && missing) {
        return missing
    }
    v = val || 0
    if (multiplier) {
        v = round(v * multiplier, precision)
    } else {
        v = round(v, precision)
    }
    let absoluteValue = Math.abs(v);
    let numValue = v;
    if (countryCode) {
        v = new Intl.NumberFormat(COUNTRY_TO_NUMBER_FORMAT[countryCode] || 'en-US', { "maximumFractionDigits": precision }).format(v)
        absoluteValue = new Intl.NumberFormat(COUNTRY_TO_NUMBER_FORMAT[countryCode] || 'en-US', { "maximumFractionDigits": precision }).format(absoluteValue)
    }
    if (prefixCurr) {
        let isNegative = numValue < 0;
        v = prefixCurrency(absoluteValue, currencyCode)
        if (isNegative) {
            v = `-${v}`
        }
    }
    if (suffix) {
        v = `${v}${suffix}`
    }
    return v;
}

function RecommendationReason({ reason }) {
    const reasons = {
        "high_acos": "High ACoS",
        "low_acos": "Low ACoS, room for higher bid",
        "low_clicks": "Limited clicks, bid higher for more traction",
        "low_cvr": "Low conversion rate, lower bid",
        "high_click": "High Clicks",
        "high_spend": "High Spending",
        "low_ctr": "Low CTR",
        "low_acos_kw": "Low ACoS"
    }

    return reasons[reason] || reason
}

const getMissingMatchTypes = (missingMatchTypes=[], keywordsList=[], query="", caps=false) => {
    if (missingMatchTypes.length) {
        return missingMatchTypes.map(t => caps ? capitalizeString(t) : t)
    } else {
        // Can be deprecated once all recommendations have been generated once -- this is just for one-time backward compatibility
        const tt = isAsin(query) ? "targets": "keywords"
        const getMT = {
            'targets': ({ resolvedExpression }) => {
                if(resolvedExpression) 
                    return resolvedExpression.filter(r => r.type == "asinSameAs").length > 0 ? 'product': null
                else
                    return null
            },
            'keywords': ({ matchType }) => matchType ? matchType.toLowerCase() : ''
        }

        const types = tt == "keywords" ? {
            'exact': 0,
            'broad': 0,
            'phrase': 0,
        } : {
            'product': 0
        }

        keywordsList.forEach(k => {
            const type = getMT[tt](k)
            if(type)
                types[type] = types[type] + 1
        })
        if(caps)
            return Object.keys(types).filter(t => types[t] == 0).map(t => capitalizeString(t))
        return Object.keys(types).filter(t => types[t] == 0)
    }
}

const RecommendedMatchType = ({ missingMatchTypes=[], keywordsList, query }) => {
    
    const missingMT = getMissingMatchTypes(missingMatchTypes, keywordsList, query, true)
    return (
        <div>{missingMT.join(' | ') || '--'}</div>
    )
}

function TargetingCell({ targeting_info = {}, small = false, showMissing = false, bgColorForZero = "red" }) {

    const bgMapForMissing = {
        'red' : '#f44236',
        'white' : 'white'
    }

    const tagettingInfoRows = small ? TARGET_INFO_KEYWORDS :
        [Object.fromEntries(Object.entries(TARGET_INFO_KEYWORDS).slice(0, 3)), Object.fromEntries(Object.entries(TARGET_INFO_KEYWORDS).slice(3))]

    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', fontWeight: 500 }}>
            {
                small ?
                    <div key={`targeting-info-row-1`}>
                        {
                            Object.keys(tagettingInfoRows).map((key, keyIndex) =>
                            {
                                let targetPresent = targeting_info[key] && targeting_info[key] > 0
                                let targetMissingButShow = targeting_info[key] && targeting_info[key] == -1
                                let bgColor = targetPresent ? '#04a9f5' : bgMapForMissing[bgColorForZero] 
                                if (targetPresent || showMissing || targetMissingButShow) {
                                    return <span data-tip={capitalizeString(key)} key={`targeting-info-row-item-${keyIndex}`} className="round-indicator" style={{ backgroundColor: bgColor, color: 'white' }}>{tagettingInfoRows[key]}</span>
                                }
                                return <></>
                            })
                        }
                    </div>
                    :
                    tagettingInfoRows.map((row, rowIndex) => (
                        <div key={`targeting-info-row-${rowIndex}`}>
                            {
                                Object.keys(row).map((key, keyIndex) => {
                                    const bgColor = targeting_info[key] && targeting_info[key] > 0 ? '#04a9f5' : bgMapForMissing[bgColorForZero] 
                                    return <span data-tip={capitalizeString(key)} key={`targeting-info-row-item-${keyIndex}`} className="round-indicator" style={{ backgroundColor : bgColor, color: 'white' }}>{row[key]}</span>
                                })
                            }
                        </div>
                    ))
            }
        </div>
    )
}

/**
 * Generates table cell with inventory data
 * @param {String} type - AFN or MFN
 * @param {InventoryData} data - Inventory data instance
 * @returns JSX
 */
const showInventoryData = (type=INVENTORY_FIELDS.AFN, data) => {
    const props = [INVENTORY_FIELDS.DAYS_OF_SUPPLY, INVENTORY_FIELDS.QUANTITY]
    const availableProps = data[type].getDefinedProperties()

    if(availableProps.length == 0)
        return (<div> -- </div>)

    return (
        <div className="merged-column">
            <div style={{ width: 94, color: 'grey' }}>
                {props.map(p => <div key={`${data[type].asin}-${type}-${p}`}>{capitalizeString(p)}</div>)}
            </div>
            <div style={{ width: 36 }}>
                {props.map(p => <div className="row-style" key={`${data[type].asin}-${type}-${p}-value`}>{data[type][p] || '--'}</div>)}
            </div>
        </div>
    )
}

/**
 * Combine four columns into one.
 * @param {String} category 
 * @param {String} sku 
 * @param {String} brand 
 * @param {String} availability 
 * @returns JSX Element
 */
const getMergedColumn = (category = '--', sku = '--', brand = '--', availability) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {category && <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <div style={{ minWidth: '70px' }}>Category</div>
                <div data-tip={category} style={{ flex: '1', minWidth: '130px', wordWrap: 'break-word' }}>{category || '--'}</div>
            </div>}
            {sku && <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <div style={{ minWidth: '70px' }}>SKU</div>
                <div style={{ flex: '1', minWidth: '130px', wordWrap: 'break-word' }}>{sku || '--'}</div>
            </div>}
            {brand && <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <div style={{ minWidth: '70px' }}>Brand</div>
                <div style={{ flex: '1', minWidth: '130px', wordWrap: 'break-word' }}>{brand || '--'}</div>
            </div>}
            {availability && <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <div style={{ minWidth: '70px' }}>Availability</div>
                <div style={{ flex: '1', minWidth: '130px', wordWrap: 'break-word' }}>{availability || '--'}</div>
            </div>}
        </div>
    )
}

function RetailPerformanceTableColumns(countryCode, prefix, prevChangeData=false, style={}) {
    if (prefix === undefined) {
        prefix = "metrics."
    }
    return (
        [
            { name: `Total ACOS`, colId: 'total_acos', cell: ({ metrics = {} }) => prevChangeData? <CreateMetricCellForExpanded row={metrics} metricKey={'total_acos'} suffix={"%"} /> : <Num countryCode={countryCode} val={metrics.total_acos} suffix="%" missing={"--"} />, sortable: true, sortField: prefix+'total_acos', selector: 'metrics.total_acos', minWidth: '100px', grow: 1, right: true, style },
            { name: `Total Orders`, colId: 'total_orders', cell: ({ metrics = {} }) => prevChangeData? <CreateMetricCellForExpanded row={metrics} metricKey={'total_orders'} /> : <Num countryCode={countryCode} val={metrics.total_orders} missing={"--"} />, sortable: true, sortField: prefix+'total_orders', selector: 'metrics.total_orders', minWidth: '100px', grow: 1, right: true, style },
            { name: `Total Sales`, colId: 'total_sales', cell: ({ metrics = {} }) => prevChangeData? <CreateMetricCellForExpanded row={metrics} metricKey={'total_sales'} prefixCurr /> : <Num countryCode={countryCode} val={metrics.total_sales} prefixCurr  missing={"--"} />, sortable: true, sortField: prefix+'total_sales', selector: 'metrics.total_sales', minWidth: '100px', grow: 1, right: true, style },
            { name: `Total Units`, colId: 'total_units', cell: ({ metrics = {} }) => prevChangeData? <CreateMetricCellForExpanded row={metrics} metricKey={'total_units'} /> : <Num countryCode={countryCode} val={metrics.total_units} missing={"--"} />, sortable: true, sortField: prefix+'total_units', selector: 'metrics.total_units', minWidth: '100px', grow: 1, right: true, style },
            { name: 'Ordered Revenue', colId: 'ordered_revenue', cell: ({ metrics = {} }) => prevChangeData? <CreateMetricCellForExpanded row={metrics} metricKey={'ordered_revenue'} prefixCurr={true}/> : <Num countryCode={countryCode} val={metrics.ordered_revenue} prefixCurr={true} />, sortable: true, wrap: false, minWidth: '140px', selector: 'metrics.ordered_revenue', sortField: prefix+'ordered_revenue', minWidth: '100px', right: true, grow: 1, style },
            { name: 'Ordered Units', colId: 'units_ordered', cell: ({ metrics = {} }) => prevChangeData? <CreateMetricCellForExpanded row={metrics} metricKey={'units_ordered'}/> : <Num countryCode={countryCode} val={metrics.units_ordered} />, sortable: true, wrap: false, minWidth: '140px', selector: 'metrics.units_ordered', sortField: prefix+'units_ordered', minWidth: '100px', grow: 1, right: true, style },
            { name: 'Ordered Orders', colId: 'ordered_orders', cell: ({ metrics = {} }) => prevChangeData? <CreateMetricCellForExpanded row={metrics} metricKey={'ordered_orders'}/> : <Num countryCode={countryCode} val={metrics.ordered_orders} />, sortable: true, wrap: false, minWidth: '140px', selector: 'metrics.ordered_orders', sortField: prefix+'ordered_orders', minWidth: '100px', grow: 1, right: true, style },
            { name: `Shipped Revenue`, colId: 'shipped_revenue', cell: ({ metrics = {} }) => prevChangeData? <CreateMetricCellForExpanded row={metrics} metricKey={'shipped_revenue'} prefixCurr={true}/> : <Num countryCode={countryCode} val={metrics.shipped_revenue} prefixCurr={true} />, sortable: true, minWidth: '140px', sortField: prefix+'shipped_revenue', selector: 'metrics.shipped_revenue', minWidth: '100px', grow: 1, right: true, style },
            { name: `Shipped Units`, colId: 'shipped_units', cell: ({ metrics = {} }) => prevChangeData? <CreateMetricCellForExpanded row={metrics} metricKey={'shipped_units'}/> : <Num countryCode={countryCode} val={metrics.shipped_units} />, sortable: true, sortField: prefix+'shipped_units', selector: 'metrics.shipped_units', minWidth: '100px', grow: 1, right: true, style },
            { name: `Shipped COGS`, colId: 'shipped_cogs', cell: ({ metrics = {} }) => prevChangeData? <CreateMetricCellForExpanded row={metrics} metricKey={'shipped_cogs'} prefixCurr={true}/> : <Num countryCode={countryCode} val={metrics.shipped_cogs} prefixCurr={true} />, minWidth: '140px', sortable: true, sortField: prefix+'shipped_cogs', selector: 'metrics.shipped_cogs', minWidth: '100px', grow: 1, right: true, style },
            { name: `Page Views`, colId: 'page_views', cell: ({ metrics = {} }) => prevChangeData? <CreateMetricCellForExpanded row={metrics} metricKey={'page_views'}/> : <Num countryCode={countryCode} val={metrics.page_views} />, sortable: true, sortField: prefix+'page_views', selector: 'metrics.page_views', minWidth: '100px', grow: 1, right: true, style },
            { name: `Sessions`, colId: 'sessions', cell: ({ metrics = {} }) => prevChangeData? <CreateMetricCellForExpanded row={metrics} metricKey={'sessions'}/> : <Num countryCode={countryCode} val={metrics.sessions} />, sortable: true, sortField: prefix+'sessions', selector: 'metrics.sessions', minWidth: '100px', grow: 1, right: true, style },
            { name: `Buy Box (%)`, colId: 'buy_box_percentage', cell: ({ metrics = {} }) => prevChangeData? <CreateMetricCellForExpanded row={metrics} metricKey={'buy_box_percentage'} suffix={"%"}/> : <Num countryCode={countryCode} val={metrics.buy_box_percentage} suffix="%" />, sortable: true, sortField: prefix+'buy_box_percentage', selector: 'metrics.buy_box_percentage', minWidth: '100px', grow: 1, right: true, style },
            { name: `Ordered Items Session (%)`, colId: 'ordered_items_session_percentage', cell: ({ metrics = {} }) => prevChangeData? <CreateMetricCellForExpanded row={metrics} metricKey={'ordered_items_session_percentage'} suffix="%"/> : <Num countryCode={countryCode} val={metrics.ordered_items_session_percentage} suffix="%" />, sortable: true, sortField: prefix+'ordered_items_session_percentage', selector: 'metrics.ordered_items_session_percentage', minWidth: '100px', grow: 1, right: true, style },
            { name: `Average Offer Count`, colId: 'average_offer_count', cell: ({ metrics = {} }) => prevChangeData? <CreateMetricCellForExpanded row={metrics} metricKey={'average_offer_count'}/> : <Num countryCode={countryCode} val={metrics.average_offer_count} />, sortable: true, sortField: prefix+'average_offer_count', selector: 'metrics.average_offer_count', minWidth: '100px', grow: 1, right: true, style },
            { name: `Feedback Received`, colId: 'feedback_received', cell: ({ metrics = {} }) => prevChangeData? <CreateMetricCellForExpanded row={metrics} metricKey={'feedback_received'}/> : <Num countryCode={countryCode} val={metrics.feedback_received} />, sortable: true, sortField: prefix+'feedback_received', selector: 'metrics.feedback_received', minWidth: '100px', grow: 1, right: true, style },
            { name: `Negative Feedback (%)`, colId: 'received_negative_feedback_rate', cell: ({ metrics = {} }) => prevChangeData? <CreateMetricCellForExpanded row={metrics} metricKey={'received_negative_feedback_rate'} suffix="%"/> : <Num countryCode={countryCode} val={metrics.received_negative_feedback_rate} suffix="%" />, sortable: true, sortField: prefix+'received_negative_feedback_rate', selector: 'metrics.received_negative_feedback_rate', minWidth: '100px', grow: 1, right: true, style },
            { name: `Claims Granted`, colId: 'claims_granted', cell: ({ metrics = {} }) => prevChangeData? <CreateMetricCellForExpanded row={metrics} metricKey={'claims_granted'}/> : <Num countryCode={countryCode} val={metrics.claims_granted} />, sortable: true, sortField: prefix+'claims_granted', selector: 'metrics.claims_granted', minWidth: '100px', grow: 1, right: true, style },
            { name: `Claims Amount`, colId: 'claims_amount', cell: ({ metrics = {} }) => prevChangeData? <CreateMetricCellForExpanded row={metrics} metricKey={'claims_amount'} prefixCurr={true}/> : <Num countryCode={countryCode} val={metrics.claims_amount} prefixCurr={true} />, sortable: true, sortField: prefix+'claims_amount', selector: 'metrics.claims_amount', minWidth: '100px', grow: 1, right: true, style },
            { name: `Browser Page Views`, colId: 'browser_page_views', cell: ({ metrics = {} }) => prevChangeData? <CreateMetricCellForExpanded row={metrics} metricKey={'browser_page_views'}/> : <Num countryCode={countryCode} val={metrics.browser_page_views} />, sortable: true, sortField: prefix+'browser_page_views', selector: 'metrics.browser_page_views', minWidth: '100px', grow: 1, right: true, style },
            { name: `Browser Sessions`, colId: 'browser_sessions', cell: ({ metrics = {} }) => prevChangeData? <CreateMetricCellForExpanded row={metrics} metricKey={'browser_sessions'}/> : <Num countryCode={countryCode} val={metrics.browser_sessions} />, sortable: true, sortField: prefix+'browser_sessions', selector: 'metrics.browser_sessions', minWidth: '100px', grow: 1, right: true, style },
            { name: `Mobile Page Views`, colId: 'mobile_page_views', cell: ({ metrics = {} }) => prevChangeData? <CreateMetricCellForExpanded row={metrics} metricKey={'mobile_page_views'}/> : <Num countryCode={countryCode} val={metrics.mobile_page_views} />, sortable: true, sortField: prefix+'mobile_page_views', selector: 'metrics.mobile_page_views', minWidth: '100px', grow: 1, right: true, style },
            { name: `Mobile Sessions`, colId: 'mobile_app_sessions', cell: ({ metrics = {} }) => prevChangeData? <CreateMetricCellForExpanded row={metrics} metricKey={'mobile_app_sessions'}/> : <Num countryCode={countryCode} val={metrics.mobile_app_sessions} />, sortable: true, sortField: prefix+'mobile_app_sessions', selector: 'metrics.mobile_app_sessions', minWidth: '100px', grow: 1, right: true, style },
        ]
    )
}

function TableColumns(targetAcos, countryCode, multiplier, optionalColumns) {
    let m = multiplier || 100
    let optionals = optionalColumns || []
    let prefix = "metrics."
    const optionalConfig = {
        'tos_is': { name: `Top-of-search IS`, colId: 'metrics_tos_is', sortable: true, cell: ({ metrics = {} }) => <Num countryCode={countryCode} val={metrics.tos_is} suffix='%' missing='--' />, sortField: prefix+'tos_is', selector: 'metrics.tos_is', right: true },
    }
    return (
        [
            { name: 'ACOS', colId: 'metrics_acos', cell: ({ metrics = {}, target_acos = targetAcos }) => { target_acos = (target_acos || targetAcos) * 100; return (<RedGreenText diff={target_acos - ((metrics.acos || (target_acos + 1)) * m)} displayVal={<Num countryCode={countryCode} val={metrics.acos} multiplier={m} missing="--" suffix="%" />} />) }, sortable: true, wrap: true, minWidth: '100px', sortField: prefix+'acos', selector: 'metrics.acos', right: true },
            { name: 'Spend', colId: 'metrics_spend', cell: ({ metrics = {} }) => <Num countryCode={countryCode} val={metrics.spend} prefixCurr={true} />, sortable: true, wrap: false, minWidth: '140px', selector: 'metrics.spend', sortField: prefix+'spend', right: true },
            { name: 'Sales', colId: 'metrics_sales', cell: ({ metrics = {} }) => <Num countryCode={countryCode} val={metrics.sales} prefixCurr={true} />, sortable: true, wrap: false, minWidth: '140px', selector: 'metrics.sales', sortField: prefix+'sales', right: true },
            { name: `Orders`, colId: 'metrics_conversions', cell: ({ metrics = {} }) => <Num countryCode={countryCode} val={metrics.conversions} />, sortable: true, minWidth: '140px', sortField: prefix+'conversions', selector: 'metrics.conversions', right: true },
            { name: `Units`, colId: 'metrics_orders', cell: ({ metrics = {} }) => <Num countryCode={countryCode} val={metrics.orders} />, sortable: true, sortField: prefix+'orders', selector: 'metrics.orders', right: true },
            { name: `Clicks`, colId: 'metrics_clicks', cell: ({ metrics = {} }) => <Num countryCode={countryCode} val={metrics.clicks} />, sortable: true, sortField: prefix+'clicks', selector: 'metrics.clicks', right: true },
            { name: `Impressions`, colId: 'metrics_impressions', cell: ({ metrics = {} }) => <Num countryCode={countryCode} val={metrics.impressions} />, sortable: true, minWidth: '140px', sortField: prefix+'impressions', selector: 'metrics.impressions', right: true },
            { name: `CTR`, colId: 'metrics_ctr', cell: ({ metrics = {} }) => <Num countryCode={countryCode} val={metrics.ctr} suffix="%" multiplier={m} />, sortable: true, sortField: prefix+'ctr', selector: 'metrics.ctr', right: true },
            { name: `CPC`, colId: 'metrics_cpc', cell: ({ metrics = {} }) => <Num countryCode={countryCode} val={metrics.cpc} prefixCurr={true} />, sortable: true, sortField: prefix+'cpc', selector: 'metrics.cpc', right: true },
            { name: `Conversion Rate`, colId: 'metrics_conversion_rate', sortable: true, cell: ({ metrics = {} }) => <Num countryCode={countryCode} val={metrics.conversion_rate} suffix='%' multiplier={m} />, sortField: prefix+'conversion_rate', selector: 'metrics.conversion_rate', right: true },
        ].concat(optionals.map(col => optionalConfig[col]))
    )
}

function SovMetricColumns(prefix = "metrics.") {
    return [
        { name: 'Current Sponsored Rank', colId: 'current_avg_sp_rank', cell: (row) => <Num val={getDeepValueFromObject(row, prefix + 'current_avg_sp_rank')} missing="--" />, sortable: true, minWidth: '140px', sortField: prefix + 'current_avg_sp_rank', selector: prefix + 'current_avg_sp_rank', right: true },
        { name: 'Average Sponsored Rank', colId: 'avg_sp_rank', cell: (row) => <Num val={getDeepValueFromObject(row, prefix + 'avg_sp_rank')} missing="--" />, sortable: true, minWidth: '140px', sortField: prefix + 'avg_sp_rank', selector: prefix + 'avg_sp_rank', right: true },
        { name: 'Current Organic Rank', colId: 'current_avg_org_rank', cell: (row) => <Num val={getDeepValueFromObject(row, prefix + 'current_avg_org_rank')} missing="--" />, sortable: true, minWidth: '140px', sortField: prefix + 'current_avg_org_rank', selector: prefix + 'current_avg_org_rank', right: true },
        { name: 'Average Organic Rank', colId: 'avg_org_rank', cell: (row) => <Num val={getDeepValueFromObject(row, prefix + 'avg_org_rank')} missing="--" />, sortable: true, minWidth: '140px', sortField: prefix + 'avg_org_rank', selector: prefix + 'avg_org_rank', right: true },
        { name: 'Current Total Rank', colId: 'current_avg_total_rank', cell: (row) => <Num val={getDeepValueFromObject(row, prefix + 'current_avg_total_rank')} missing="--" />, sortable: true, minWidth: '140px', sortField: prefix + 'current_avg_total_rank', selector: prefix + 'current_avg_total_rank', right: true },
        { name: 'Average Total Rank', colId: 'avg_total_rank', cell: (row) => <Num val={getDeepValueFromObject(row, prefix + 'avg_total_rank')} missing="--" />, sortable: true, minWidth: '140px', sortField: prefix + 'avg_total_rank', selector: prefix + 'avg_total_rank', right: true },
        { name: 'Current Sponsored Share', colId: 'current_sp_sov', cell: (row) => <Num val={getDeepValueFromObject(row, prefix + 'current_sp_sov')} missing="--" suffix='%' />, sortable: true, minWidth: '140px', sortField: prefix + 'current_sp_sov', selector: prefix + 'current_sp_sov', right: true },
        { name: 'Average Sponsored Share', colId: 'sp_sov', cell: (row) => <Num val={getDeepValueFromObject(row, prefix + 'sp_sov')} missing="--" suffix='%' />, sortable: true, minWidth: '140px', sortField: prefix + 'sp_sov', selector: prefix + 'sp_sov', right: true },
        { name: 'Current Organic Share', colId: 'current_organic_sov', cell: (row) => <Num val={getDeepValueFromObject(row, prefix + 'current_organic_sov')} missing="--" suffix='%' />, sortable: true, minWidth: '140px', sortField: prefix + 'current_organic_sov', selector: prefix + 'current_organic_sov', right: true },
        { name: 'Average Organic Share', colId: 'organic_sov', cell: (row) => <Num val={getDeepValueFromObject(row, prefix + 'organic_sov')} missing="--" suffix='%' />, sortable: true, minWidth: '140px', sortField: prefix + 'organic_sov', selector: prefix + 'organic_sov', right: true },
        { name: 'Current Total Share', colId: 'current_total_sov', cell: (row) => <Num val={getDeepValueFromObject(row, prefix + 'current_total_sov')} missing="--" suffix='%' />, sortable: true, minWidth: '140px', sortField: prefix + 'current_total_sov', selector: prefix + 'current_total_sov', right: true },
        { name: 'Average Total Share', colId: 'total_sov', cell: (row) => <Num val={getDeepValueFromObject(row, prefix + 'total_sov')} missing="--" suffix='%' />, sortable: true, minWidth: '140px', sortField: prefix + 'total_sov', selector: prefix + 'total_sov', right: true },
        { name: 'Current ASIN Average Sponsored Rank', colId: 'current_asin_avg_sp_rank', cell: (row) => <Num val={getDeepValueFromObject(row, prefix + 'current_asin_avg_sp_rank')} missing="--" />, sortable: true, minWidth: '140px', sortField: prefix + 'current_asin_avg_sp_rank', selector: prefix + 'current_asin_avg_sp_rank', right: true },
        { name: 'Current ASIN Minimum Sponsored Rank', colId: 'current_asin_min_sp_rank', cell: (row) => <Num val={getDeepValueFromObject(row, prefix + 'current_asin_min_sp_rank')} missing="--" />, sortable: true, minWidth: '140px', sortField: prefix + 'current_asin_min_sp_rank', selector: prefix + 'current_asin_min_sp_rank', right: true },
        { name: 'Current ASIN Average Organic Rank', colId: 'current_asin_avg_org_rank', cell: (row) => <Num val={getDeepValueFromObject(row, prefix + 'current_asin_avg_org_rank')} missing="--" />, sortable: true, minWidth: '140px', sortField: prefix + 'current_asin_avg_org_rank', selector: prefix + 'current_asin_avg_org_rank', right: true },
        { name: 'Current ASIN Minimum Organic Rank', colId: 'current_asin_min_org_rank', cell: (row) => <Num val={getDeepValueFromObject(row, prefix + 'current_asin_min_org_rank')} missing="--" />, sortable: true, minWidth: '140px', sortField: prefix + 'current_asin_min_org_rank', selector: prefix + 'current_asin_min_org_rank', right: true },
        { name: 'Current ASIN Average Total Rank', colId: 'current_asin_avg_total_rank', cell: (row) => <Num val={getDeepValueFromObject(row, prefix + 'current_asin_avg_total_rank')} missing="--" />, sortable: true, minWidth: '140px', sortField: prefix + 'current_asin_avg_total_rank', selector: prefix + 'current_asin_avg_total_rank', right: true },
        { name: 'Current ASIN Minimum Total Rank', colId: 'current_asin_min_total_rank', cell: (row) => <Num val={getDeepValueFromObject(row, prefix + 'current_asin_min_total_rank')} missing="--" />, sortable: true, minWidth: '140px', sortField: prefix + 'current_asin_min_total_rank', selector: prefix + 'current_asin_min_total_rank', right: true },
    ]
}

function AdvertisingMetricsColumns({accountTargetAcos, countryCode, prefix = "metrics.", columnNamePrefix = "", targetAcosPrefix = undefined, optionals = [], multiplier = 100, prevChangeData=false, style={}}) {
    const optionalConfig = {
        'roas': { name: columnNamePrefix + 'ROAS', colId: prefix + 'metrics_roas', sortable: true, cell: (row) => <Num countryCode={countryCode} val={getDeepValueFromObject(row, prefix + 'roas')} missing="--" />, sortField: prefix + 'roas', selector: prefix + 'roas', right: true, style },
    }
    return [
        {
            name: columnNamePrefix + 'ACOS', colId: prefix + 'metrics_acos', cell: (row) => {
                const targetAcosKey = prevChangeData ? 'target_acos.current' : 'target_acos';
                const acosKey = prevChangeData ? 'acos.current' : 'acos';
                let targetAcos = getDeepValueFromObject(row, (targetAcosPrefix === undefined ? prefix : targetAcosPrefix) + targetAcosKey, accountTargetAcos)
                let diff = targetAcos - getDeepValueFromObject(row, prefix + acosKey, targetAcos + 1)
                let acos = getDeepValueFromObject(row, prefix + acosKey, null)
                if (prevChangeData){
                    let change_prec = getDeepValueFromObject(row, prefix + 'acos.change_perc')
                    const backgroundColor = change_prec >= 0 ? '#e6f4ea' : '#f4e6ea';
                    return  <div style={{ "padding": "7px 0px", "width": "100%", textAlign: "right" }}>
                            <div style={{ marginTop: "15px" }}>
                                <RedGreenText diff={diff} displayVal={<Num countryCode={countryCode} val={acos} multiplier={multiplier} suffix='%' missing='--' />} />
                            </div>
                            <div className="mt-2 text-muted" style={{ marginBottom: "15px" }}>
                                (<RedGreenText diff={-change_prec} displayVal={<Num countryCode={countryCode} val={change_prec} multiplier={multiplier} suffix='%' missing='--' />} />)
                            </div>
                        </div>
                } else{
                    return <RedGreenText diff={diff} displayVal={<Num countryCode={countryCode} val={acos} multiplier={multiplier} suffix='%' missing='--' />} />
                }
            }, sortable: true, minWidth: '140px', sortField: prefix + (prevChangeData ? 'acos.current' : 'acos'), selector: prefix + (prevChangeData ? 'acos.current' : 'acos'), right: true, style
        },
        { name: columnNamePrefix + 'Spend', colId: prefix + 'metrics_spend', cell: (row) => prevChangeData ? <CreateMetricCellForExpanded row={row} metricKey={prefix + 'spend'} prefixCurr={true}/> : <Num countryCode={countryCode} val={getDeepValueFromObject(row, prefix + 'spend')} prefixCurr={true} />, sortable: true, minWidth: '140px', sortField: prefix + 'spend', selector: prefix + 'spend', right: true, style },
        { name: columnNamePrefix + 'Sales', colId: prefix + 'metrics_sales', cell: (row) => prevChangeData ? <CreateMetricCellForExpanded row={row} metricKey={prefix + 'sales'} prefixCurr={true}/> : <Num countryCode={countryCode} val={getDeepValueFromObject(row, prefix + 'sales')} prefixCurr={true} />, sortable: true, minWidth: '140px', sortField: prefix + 'sales', selector: prefix + 'sales', right: true, style },
        { name: columnNamePrefix + `Orders`, colId: prefix + 'metrics_conversions', cell: (row) => prevChangeData ? <CreateMetricCellForExpanded row={row} metricKey={prefix + 'conversions'}/> : <Num countryCode={countryCode} val={getDeepValueFromObject(row, prefix + 'conversions')} />, sortable: true, minWidth: '140px', sortField: prefix + 'conversions', selector: prefix + 'conversions', right: true, style },
        { name: columnNamePrefix + `Units`, colId: prefix + 'metrics_orders', cell: (row) => prevChangeData ? <CreateMetricCellForExpanded row={row} metricKey={prefix + 'orders'}/> : <Num countryCode={countryCode} val={getDeepValueFromObject(row, prefix + 'orders')} />, sortable: true, minWidth: '140px', sortField: prefix + 'orders', selector: prefix + 'orders', right: true, style },
        { name: columnNamePrefix + `Clicks`, colId: prefix + 'metrics_clicks', cell: (row) => prevChangeData ? <CreateMetricCellForExpanded row={row} metricKey={prefix + 'clicks'}/> : <Num countryCode={countryCode} val={getDeepValueFromObject(row, prefix + 'clicks')} />, sortable: true, minWidth: '140px', sortField: prefix + 'clicks', selector: prefix + 'clicks', right: true, style },
        { name: columnNamePrefix + `Impressions`, colId: prefix + 'metrics_impressions', cell: (row) => prevChangeData ? <CreateMetricCellForExpanded row={row} metricKey={prefix + 'impressions'}/> : <Num countryCode={countryCode} val={getDeepValueFromObject(row, prefix + 'impressions')} />, sortable: true, minWidth: '140px', sortField: prefix + 'impressions', selector: prefix + 'impressions', right: true, style },
        { name: columnNamePrefix + `CTR`, colId: prefix + 'metrics_ctr', cell: (row) => prevChangeData ? <CreateMetricCellForExpanded row={row} metricKey={prefix + 'ctr'} suffix={'%'} multiplier={multiplier}/> : <Num countryCode={countryCode} val={getDeepValueFromObject(row, prefix + 'ctr')} suffix="%" multiplier={multiplier} />, sortable: true, minWidth: '140px', sortField: prefix + 'ctr', selector: prefix + 'ctr', right: true, style },
        { name: columnNamePrefix + `CPC`, colId: prefix + 'metrics_cpc', cell: (row) => prevChangeData ? <CreateMetricCellForExpanded row={row} metricKey={prefix + 'cpc'} prefixCurr={true}/> : <Num countryCode={countryCode} val={getDeepValueFromObject(row, prefix + 'cpc')} prefixCurr={true} />, sortable: true, minWidth: '140px', sortField: prefix + 'cpc', selector: prefix + 'cpc', right: true, style },
        { name: columnNamePrefix + `Conversion Rate`, colId: prefix + 'metrics_conversion_rate', sortable: true, cell: (row) => prevChangeData ? <CreateMetricCellForExpanded row={row} metricKey={prefix + 'conversion_rate'} suffix={'%'} multiplier={multiplier}/> : <Num countryCode={countryCode} val={getDeepValueFromObject(row, prefix + 'conversion_rate')} suffix='%' multiplier={multiplier} />, sortField: prefix + 'conversion_rate', selector: prefix + 'conversion_rate', right: true, style },
    ].concat(optionals.map(col => optionalConfig[col]))
}

function ACOSColumn({ name, key, accountTargetAcos, columnNamePrefix, prefix, countryCode, multiplier, style, hidden_by_default = false }) {
    return {
        name: `${columnNamePrefix}${name}`, colId: `${prefix}${key}`, cell: (row) => {
            const diff = accountTargetAcos - getDeepValueFromObject(row, `${prefix}${key}`, accountTargetAcos + 1);
            const acos = getDeepValueFromObject(row, `${prefix}${key}`, null);
            
            return <RedGreenText diff={diff} displayVal={<Num countryCode={countryCode} val={acos} multiplier={multiplier} suffix='%' missing='--' />} />;
        }, sortable: true, minWidth: '150px', sortField: `${prefix}${key}`, selector: `${prefix}${key}`, right: true, style, hidden_by_default
    }
}

function NumericColumn({ name, key, prefix, countryCode, style, hidden_by_default = false }) {
    return { name, colId: prefix + key, cell: (row) => <Num countryCode={countryCode} val={getDeepValueFromObject(row, prefix + key)} precision={4} missing={"--"} />, sortable: true, minWidth: '150px', sortField: prefix + key, selector: prefix + key, right: true, style, hidden_by_default }
}

function PercentageColumn({ name, key, prefix, countryCode, style, multiplier, hidden_by_default = false }) {
    return { name, colId: prefix + key, cell: (row) => <Num countryCode={countryCode} val={getDeepValueFromObject(row, prefix + key)} suffix="%" multiplier={multiplier} precision={4} missing={"--"} />, sortable: true, minWidth: '150px', sortField: prefix + key, selector: prefix + key, right: true, style, hidden_by_default }
}

function CurrencyColumn({ name, key, prefix, countryCode, style, hidden_by_default = false }) {
    return { name, colId: prefix + key, cell: (row) => <Num countryCode={countryCode} val={getDeepValueFromObject(row, prefix + key)} prefixCurr={true} missing={"--"} />, sortable: true, minWidth: '150px', sortField: prefix + key, selector: prefix + key, right: true, style, hidden_by_default }
}

function DSPMetricsColumns({ accountTargetAcos, countryCode, prefix = "metrics.", columnNamePrefix = "", multiplier = 100, style={}, filterKey }) {
    return Object.values(DSPMetricConfigs)
        .filter(({ whitelistedKeys }) => whitelistedKeys.includes(filterKey))
        .map(({ label: name, attribute: key, metricType, hidden_by_default }) => {
            if (metricType === METRIC_TYPES.ACOS) {
                return ACOSColumn({ name, key, accountTargetAcos, columnNamePrefix, prefix, countryCode, multiplier, style, hidden_by_default })
            } else if (metricType === METRIC_TYPES.NUMERIC) {
                return NumericColumn({ name, key, prefix, countryCode, style, hidden_by_default })
            } else if (metricType === METRIC_TYPES.PERCENTAGE) {
                return PercentageColumn({ name, key, prefix, countryCode, style, multiplier, hidden_by_default })
            } else if (metricType === METRIC_TYPES.CURRENCY) {
                return CurrencyColumn({ name, key, prefix, countryCode, style, hidden_by_default })
            } else {
                throw new Error(`Metric type ${metricType} is not supported`)
            }
        }
    );
}

function AMCCamapignColumns(granularity = AMC_GRANULARITY.CAMPAIGN) {
    if (granularity === AMC_GRANULARITY.CAMPAIGN) {
        return [
            { name: 'Campaign Name', colId: 'campaign', cell: ({ campaign, campaign_type, campaign_id, aggregate }) => aggregate ? "Total" : campaign_id != null ? <CampaignName campaign={{ name: campaign, campaign_type, campaignId: campaign_id }} /> : campaign, selector: 'campaign', width: '250px', alwaysShow: true, sortable: true, sortField: 'campaign' },
            { name: 'Campaign Type', colId: 'campaign_type', cell: ({ campaign_type, aggregate }) => aggregate ? "--" : AMC_CAMPAIGN_TYPE_TO_LABEL[campaign_type], selector: 'campaign_type', width: "210px", alwaysShow: true, sortable: true, sortField: 'campaign_type' },
        ]
    } else if (granularity === AMC_GRANULARITY.CAMPAIGN_TYPE) {
        return [
            { name: 'Campaign Type', colId: 'campaign_type', cell: ({ campaign_type, aggregate }) => aggregate ? "Total" : AMC_CAMPAIGN_TYPE_TO_LABEL[campaign_type], selector: 'campaign_type', width: "210px", alwaysShow: true, sortable: true, sortField: 'campaign_type' },
        ]
    } else {
        throw new Error(`Granularity ${granularity} is not supported`);
    }
}

function AMCMetricsColumns({targetACOS, countryCode, metrics, prefix = "", columnNamePrefix = "", ...props }) {
    const metricsConfig = {
        [AMC_METRICS.REACH]: { name: 'Reach', colId: 'reach', cell: (val) => <Num countryCode={countryCode} val={val} />, selector: 'reach', sortable: true, sortField: 'reach', ...props },
        [AMC_METRICS.CUSTOMERS]: { name: 'Customers', colId: 'customers', cell: (val) => <Num countryCode={countryCode} val={val} />, selector: 'customers', sortable: true, sortField: 'customers', ...props },
        [AMC_METRICS.PERCENTAGE]: { name: 'Percentage', colId: 'percentage', cell: (val) => <Num countryCode={countryCode} val={val * 100} suffix='%' />, selector: 'percentage', sortable: true, sortField: 'percentage', ...props },
        [AMC_METRICS.ORDERS]: { name: 'Orders', colId: 'orders', cell: (val) => <Num countryCode={countryCode} val={val} />, selector: 'orders', sortable: true, sortField: 'orders', ...props },
        [AMC_METRICS.SALES]: { name: 'Sales', colId: 'sales', cell: (val) => <Num countryCode={countryCode} val={val} prefixCurr={true} />, selector: 'sales', sortable: true, sortField: 'sales', ...props },
        [AMC_METRICS.SPEND]: { name: 'Spend', colId: 'spend', cell: (val) => <Num countryCode={countryCode} val={val} prefixCurr={true} />, selector: 'spend', sortable: true, sortField: 'spend', ...props },
        [AMC_METRICS.IMPRESSIONS]: { name: 'Impressions', colId: 'impressions', cell: (val) => <Num countryCode={countryCode} val={val} />, selector: 'impressions', sortable: true, sortField: 'impressions', ...props },
        [AMC_METRICS.CLICKS]: { name: 'Clicks', colId: 'clicks', cell: (val) => <Num countryCode={countryCode} val={val} />, selector: 'clicks', sortable: true, sortField: 'clicks', ...props },
        [AMC_METRICS.CTR]: { name: 'CTR', colId: 'ctr', cell: (val) => <Num countryCode={countryCode} val={val * 100} suffix="%" />, selector: 'ctr', sortable: true, sortField: 'ctr', ...props },
        [AMC_METRICS.ACOS]: { name: 'ACOS', colId: 'acos', cell: (val) => <RedGreenText diff={(targetACOS - val) * 100} displayVal={<Num countryCode={countryCode} val={val * 100} suffix='%' />} />, selector: 'acos', sortable: true, sortField: 'acos', ...props },
        [AMC_METRICS.ROAS]: { name: 'ROAS', colId: 'roas', cell: (val) => <Num countryCode={countryCode} val={val} />, selector: 'roas', sortable: true, sortField: 'roas', ...props },
        [AMC_METRICS.CPM]: { name: 'CPM', colId: 'cpm', cell: (val) => <Num countryCode={countryCode} val={val} prefixCurr={true} />, selector: 'cpm', sortable: true, sortField: 'cpm', ...props },
        [AMC_METRICS.CONVERSION_RATE]: { name: 'Conversion Rate', colId: 'conversion_rate', cell: (val) => <Num countryCode={countryCode} val={val * 100} suffix='%' />, selector: 'conversion_rate', sortable: true, sortField: 'conversion_rate', ...props },
        [AMC_METRICS.CONVERSION_RATE_PER_THOUSAND_IMPRESSIONS]: { name: 'Conversion Rate per Thousand Impressions', colId: 'conversion_rate_per_thousand_impressions', cell: (val) => <Num countryCode={countryCode} val={val * 100} suffix="%" />, selector: 'conversion_rate_per_thousand_impressions', sortable: true, sortField: 'conversion_rate_per_thousand_impressions', ...props },
        [AMC_METRICS.CONVERSION_RATE_PER_CLICK]: { name: 'Conversion Rate per Click', colId: 'conversion_rate_per_click', cell: (val) => <Num countryCode={countryCode} val={val * 100} suffix="%" />, selector: 'conversion_rate_per_click', sortable: true, sortField: 'conversion_rate_per_click', ...props },
    }

    for (const metricConfig of Object.values(metricsConfig)) {
        const cell_func = metricConfig.cell
        metricConfig.name = columnNamePrefix + metricConfig.name;
        metricConfig.colId = prefix + metricConfig.colId;
        metricConfig.selector = prefix + metricConfig.selector;
        metricConfig.sortField = prefix + metricConfig.sortField;
        metricConfig.cell = (row) => {
            const value = getDeepValueFromObject(row, metricConfig.selector, null);
            return value != null ? cell_func(value) : "--"
        }
    }

    return metrics.map(metric => {
        if (!Object.prototype.hasOwnProperty.call(metricsConfig, metric)) {
            throw new Error(`Metric ${metric} is not supported`);
        }

        return metricsConfig[metric];
    });
}

const aggregateRowStyle = [
    {
        when: row => row.aggregate && !row.showsChange,
        style: {
            minHeight: '30px',
            backgroundColor: '#fafafa',
            fontWeight: 'bold'
        },
    }, {
        when: row => row.aggregate && row.showsChange,
        style: {
            minHeight: '48px',
            backgroundColor: '#fafafa',
            fontWeight: 'bold'
        },
    }
]

const largeAggregateRowStyle = [{
    when: row => row.aggregate,
    style: {
        minHeight: '60px',
        backgroundColor: '#fafafa',
        fontWeight: 'bold'
    },
}]

const customDataTableStyle = {
    headCells: {
        style: {
            fontSize: '13px',
            fontWeight: 'bold',
            color: '#888'
        }
    },
    rows: {
        style: {
            fontSize: '13px',
        }
    }
}

const largeCustomDataTableStyle = {
    headCells: {
        style: {
            fontSize: '13px',
            fontWeight: 'bold',
            color: '#888'
        }
    },
    rows: {
        style: {
            fontSize: '13px',
            minHeight: "60px"
        }
    }
}

export {
    CampaignName,
    TargetType,
    Num,
    RecommendationReason,
    TableColumns,
    TargetingCell,
    getMergedColumn,
    aggregateRowStyle,
    customDataTableStyle,
    CampaignThresholdCell,
    RetailPerformanceTableColumns,
    BiddingStrategy,
    CampaignBudget,
    RecommendedMatchType,
    getMissingMatchTypes,
    largeAggregateRowStyle,
    largeCustomDataTableStyle,
    showInventoryData,
    SovMetricColumns,
    AdGroupName,
    AdvertisingMetricsColumns,
    DSPMetricsColumns,
    AMCMetricsColumns,
    AMCCamapignColumns
}