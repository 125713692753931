import React, { useContext, createContext, useState, useEffect } from "react";
import moment from "moment";
import { useAuth } from "./AuthContext";
import ReactGA from 'react-ga4'
import { AnalyticsBrowser } from '@segment/analytics-next'

const identifyUser = (userId, userEmail, userName, organizationName, event_type) => {
    segmentAnalytics.identify(userId, {
        email: userEmail,
        name: userName,
        company: organizationName,
        event_type: event_type
    })
}

const usageTrackingContext = createContext();
const segmentAnalytics = AnalyticsBrowser.load({ writeKey: 'Y8Y0Hh0uRTRVoef0KplQQ7aGeaFddng5' })
ReactGA.initialize('G-GLGJQNPDG5');

function useUsageTracking() {
    return useContext(usageTrackingContext);
}

function ProvideUsageTracking({ children }) {
    const usageTracking = useProvideUsageTracking();
    return (
        <usageTrackingContext.Provider value={usageTracking}>
            {children}
        </usageTrackingContext.Provider>
    )
}

function useProvideUsageTracking() {
    
    const {organization = {}, user= {}} = useAuth()

    useEffect(() => {
        ReactGA.initialize('G-GLGJQNPDG5');
    }, [])


    useEffect(() => {
        async function identifyUserIfNotSet() {
            const org_name = organization?.name
            if (org_name && user.name && user.user_id) {
                const suser = await segmentAnalytics.user()
                if (!suser.id()) {
                    identifyUser(user._id, user.email, user.name, org_name, 'login')
                }
            }
        }
        identifyUserIfNotSet()
    }, [organization, user])


    const identifyUser = (userId, userEmail, userName, organizationName, event_type) => {
        // Segment
        segmentAnalytics.identify(userId, {
            email: userEmail,
            name: userName,
            company: organizationName,
            event_type: event_type
        })
        // GA
        const user_name_id = `${userName}|${userId}`
        ReactGA.set({
            'user_properties': {
                'org_name': organizationName,
                'user_name_id': user_name_id
            },
            "custom_map": {
                "dimension1": "event_context"
            }
        })
    }

    const logPageView = () => {
        segmentAnalytics.page();
        ReactGA.send({ hitType: "pageview", page: `${window.location.pathname} + ${window.location.search}` });
    }

    const sendEvent = (action, label, value, category) => {
        // segment
        if (action !== 'PAGE_VIEW') {
            segmentAnalytics.track(`${action} - ${label}`, {
                action: action,
                event_context: label,
                event_label: label
            })
        }
        // GA
        ReactGA.event(action, { 
            event_context: label,
            event_label: label
        });
    }

    return {
        logPageView, sendEvent, identifyUser
    }
    
}

const EVENT_CATEGORIES = {
    'PAGE_VIEW': 'NAVIGATION',
    'MODAL_OPEN': 'NAVIGATION',
    'MODAL_CLOSE': 'NAVIGATION',
    'TAB_OPEN': 'NAVIGATION',
    'FORM_SUBMIT': 'INTERACTION',
    'BUTTON_CLICK': 'INTERACTION',
    'CLICK': 'INTERACTION',
    'SUBMIT_SUCCESS': 'INTERACTION',
    'SUBMIT_FAILURE': 'INTERACTION',
    'FILTER': 'INTERACTION',
    'ACTION': 'INTERACTION'
}

const EVENT_ACTIONS = {
    'PAGE_VIEW': 'PAGE_VIEW',
    'MODAL_OPEN': 'MODAL_OPEN',
    'MODAL_CLOSE': 'MODAL_CLOSE',
    'TAB_OPEN': 'TAB_OPEN',
    'FORM_SUBMIT': 'FORM_SUBMIT',
    'BUTTON_CLICK': 'BUTTON_CLICK',
    'CLICK': 'CLICK',
    'SUBMIT_SUCCESS': 'SUBMIT_SUCCESS',
    'SUBMIT_PARTIAL_SUCCESS': 'SUBMIT_PARTIAL_SUCCESS',
    'SUBMIT_FAILURE': 'SUBMIT_FAILURE',
    'FILTER': 'FILTER',
    'ACTION': 'ACTION'
}

export {
    useUsageTracking,
    ProvideUsageTracking,
    EVENT_ACTIONS,
    identifyUser
}