import React, { useContext, createContext, useState } from "react";
import { getCampaigns, getProducts, getPortfolios, getProductDetails, getRulesets, getLabels, getDaypartingStrategies, getAudiences } from "../service";
import { useAuth } from "./AuthContext";

const dataProviderContext = createContext();

function useDataProvider() {
    return useContext(dataProviderContext);
}

function ProvideDataProvider({ children }) {
    const dataProvider = useProvideDataProvider();
    return (
        <dataProviderContext.Provider value={dataProvider}>
            {children}
        </dataProviderContext.Provider>
    )
}

function useProvideDataProvider() {
    const [ campaigns, setCampaigns ] = useState([])
    const [ productWithoutMetrics, setProductWithoutMetrics ] = useState([])
    const [productDetails, setProductDetails] = useState([])
    const [portfolios, setPortfolios] = useState([])
    const [rulesets, setRulesets] = useState([])
    const [labels, setLabels] = useState([])
    const [campaignLabels, setCampaignLabels] = useState([])
    const [daypartingStrategies, setDaypartingStrategies] = useState([])
    const {authenticated} = useAuth()
    const [productWithoutMetricsLoaded, setProductWithoutMetricsLoaded] = useState(false)
    const [dataLoaded, setDataLoaded] = useState(false)
    const [audiences, setAudiences] = useState([])

    const refreshProductDetails = async () => {
        const productDetailsData = await getProductDetails()
        setProductDetails(productDetailsData.product_details)
    }

    const refreshLabels = async (type) => {
        try {
            const data = await getLabels({type: type})
            if (type=='products') setLabels(data.labels)
            else if (type == 'campaigns') setCampaignLabels(data.labels)
        } catch (e) {
            console.log({ e });
        }
    }

    const getProductWithoutMetrics = async () => {
        if (!productWithoutMetricsLoaded) {
            const {products} = await getProducts({no_metrics: true})
            setProductWithoutMetrics(products)
            setProductWithoutMetricsLoaded(true)
        }
    }
    
    const fetchData = async () => {
        try {
            const [campaignData, portfoliosData, rulesetsData, labels, campaignLabels, daypartingStrategies, audiencesData] = await Promise.all(
                [
                    getCampaigns(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, ['campaignId', 'name', 'state', 'campaign_type', 'labels', 'targetingType', 'portfolioId'], true), 
                    getPortfolios(), getRulesets({ active: true }), getLabels({ type: 'products' }), getLabels({ type: 'campaigns' }), getDaypartingStrategies(), getAudiences()
                ]
            )
            setCampaigns(campaignData.campaigns)
            setRulesets(rulesetsData.rulesets)
            setLabels(labels.labels)
            setCampaignLabels(campaignLabels.labels)
            setPortfolios(portfoliosData.portfolios)
            setDaypartingStrategies(daypartingStrategies.strategies)
            setDataLoaded(true)
            setAudiences(audiencesData)
        } catch (e) {
            console.log({ e });
        }
    }

    const fetchRulesets = async () => {
        const {rulesets} = await getRulesets({active: true});
        setRulesets(rulesets)
    }

    const refreshAllData = () => {
        fetchData()
    }

    return {
        campaigns,
        campaignLabels,
        getProductWithoutMetrics,
        productWithoutMetrics,
        productWithoutMetricsLoaded,
        portfolios,
        productDetails,
        rulesets,
        fetchRulesets,
        labels,
        refreshLabels,
        refreshProductDetails,
        daypartingStrategies,
        refreshAllData,
        dataLoaded,
        audiences
    }
}

export {
    useDataProvider,
    ProvideDataProvider
}